import { useState, useContext } from "react"
import { useTranslation } from "react-i18next"
import { GameContext } from "pages/GameContext"
import { Row, Col } from "components/layout"
import Upgrade from "components/builds/Upgrade"
import styles from "./TransportShip.module.scss"
import ProgressBar from "components/feedback/ProgressBar"
import { Slider } from "@mui/material"
import { ScifiButton } from "components/general"
import SimpleAlert from "components/builds/SimpleAlert"
import { usePlanetById } from "data/queries/planet"
import { energyBalance, metalBalance } from "data/queries/balance"
import {
  TRITIUM_TRANSPORTSHIP_CAPACITY,
  TRITIUM_TRANSPORTSHIP_PRICE,
} from "config/constants"
import { createTransportship } from "data/queries/factory"
import { useQueryClient } from "react-query"
import { queryKey } from "data/query"
import { useSpeedMining } from "utils/hooks/gameModeEffect"

interface Props {
  building: Building
  build: Build
  setIsOpen: any
  planet: string
}

const TransportShip = (props: Props) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const { player } = useContext(GameContext)

  const currentShips = player?.transportShips || 0

  const { building, build, setIsOpen, planet } = props
  const [worker, setWorker] = useState<number>(0)
  const selectedPlanet = usePlanetById(planet)
  const [alert, setAlert] = useState<boolean>(false)
  const speedMining = useSpeedMining(planet ?? "0")

  const handleSliderChange = (event: Event, value: number | number[]) => {
    if (!(selectedPlanet.data instanceof Array) && selectedPlanet.data) {
      const numWorkers = Array.isArray(value) ? value[0] : value
      const energy = Number(energyBalance(selectedPlanet.data))
      const metal = Number(metalBalance(selectedPlanet.data, speedMining))
      const maxWorkersBasedOnEnergy = Math.floor(
        energy / TRITIUM_TRANSPORTSHIP_PRICE
      )
      const maxWorkersBasedOnMetal = Math.floor(
        metal / TRITIUM_TRANSPORTSHIP_PRICE
      )
      const maxWorkers = Math.min(
        maxWorkersBasedOnEnergy,
        maxWorkersBasedOnMetal
      )
      setWorker(Math.min(numWorkers, maxWorkers))
    }
  }

  const maxTransportships = 10

  const initialValue = 0

  const canCreate = maxTransportships * Number(building.level)

  const MaxbyLevel = initialValue + canCreate

  const disableButton =
    !selectedPlanet.data ||
    selectedPlanet.data instanceof Array ||
    Number(energyBalance(selectedPlanet.data)) <= TRITIUM_TRANSPORTSHIP_PRICE ||
    Number(metalBalance(selectedPlanet.data, speedMining)) <=
      TRITIUM_TRANSPORTSHIP_PRICE ||
    MaxbyLevel - Number(currentShips) <= 0

  function formatNumber(value: number) {
    if (value >= 1000000000) {
      const billions = value / 1000000000
      return (Math.floor(billions * 100) / 100).toFixed(2) + "B"
    } else if (value >= 1000000) {
      const millions = value / 1000000
      return (Math.floor(millions * 100) / 100).toFixed(2) + "M"
    } else if (value >= 1000) {
      const thousands = value / 1000
      return (Math.floor(thousands * 100) / 100).toFixed(2) + "k"
    } else {
      return value.toString()
    }
  }

  const calculatePrice = (numShips: number) => {
    return TRITIUM_TRANSPORTSHIP_PRICE * (numShips > 0 ? numShips : 1)
  }

  return (
    <>
      <div>
        <span className={styles.level}>{Number(building.level)}</span>
        <span>{t("level")}</span>
      </div>
      <div>
        {selectedPlanet.data && !(selectedPlanet.data instanceof Array) && (
          <>
            {Number(energyBalance(selectedPlanet.data)) <
              TRITIUM_TRANSPORTSHIP_PRICE &&
            Number(metalBalance(selectedPlanet.data, speedMining)) <
              TRITIUM_TRANSPORTSHIP_PRICE ? (
              <div className={styles.generalAlert}>
                {t("No Metal and Energy available")}
              </div>
            ) : Number(energyBalance(selectedPlanet.data)) <
              TRITIUM_TRANSPORTSHIP_PRICE ? (
              <div className={styles.generalAlert}>
                <>{t("No Energy available")}</>
              </div>
            ) : Number(metalBalance(selectedPlanet.data, speedMining)) <
              TRITIUM_TRANSPORTSHIP_PRICE ? (
              <div className={styles.generalAlert}>
                {t("No Metal available")}
              </div>
            ) : null}
          </>
        )}

        <div style={{ display: "flex" }}>
          <div>
            <img
              src={`/assets/builds/build-10.png`}
              alt={t("Shipyard")}
              className={styles.buildImage}
              draggable={false}
              style={{ width: "220px" }}
            />
            <Upgrade setIsOpen={setIsOpen} build={build} building={building} />
          </div>
          <div style={{ marginLeft: "20px" }}></div>
          <div className={styles.buildContent}>
            {selectedPlanet.data &&
              !(selectedPlanet.data instanceof Array) &&
              Number(energyBalance(selectedPlanet.data)) > 0 && (
                <>
                  <div className={styles.tip}>
                    <div className={styles.label}>
                      <img
                        src="/assets/icons/resources/energy.png"
                        alt="energy"
                        style={{ width: "30px" }}
                      />
                      {formatNumber(calculatePrice(worker))}
                    </div>
                    <span className={styles.stats}>
                      <div
                        style={{
                          position: "relative",
                          right: "100px",
                          top: "10px",
                        }}
                      >
                        <ProgressBar
                          filledColor="#90ee90"
                          color="#90ee9033"
                          size="custom-build"
                          currentValue={Number(
                            energyBalance(selectedPlanet.data)
                          )}
                          maxValue={TRITIUM_TRANSPORTSHIP_PRICE}
                          bars={Number(5)}
                        />
                      </div>
                      <div className={styles.priceLabel}>
                        {t("Available: ")}
                        {formatNumber(
                          Math.floor(
                            Math.max(
                              Number(energyBalance(selectedPlanet.data)) -
                                worker * TRITIUM_TRANSPORTSHIP_PRICE,
                              0
                            )
                          )
                        )}
                      </div>
                    </span>
                  </div>
                  <div className={styles.tipMetal}>
                    <div className={styles.label}>
                      <img
                        src="/assets/icons/resources/metal.png"
                        alt="metal"
                        style={{ width: "30px" }}
                      />
                      {formatNumber(calculatePrice(worker))}
                    </div>
                    <span className={styles.stats}>
                      <div
                        style={{
                          position: "relative",
                          right: "110px",
                          top: "10px",
                        }}
                      >
                        <ProgressBar
                          filledColor="#fefb53"
                          color="#fefb5331"
                          size="custom-build"
                          currentValue={Number(
                            metalBalance(selectedPlanet.data, speedMining)
                          )}
                          maxValue={TRITIUM_TRANSPORTSHIP_PRICE}
                          bars={Number(5)}
                        />
                      </div>
                      <div className={styles.priceLabel}>
                        {t("Available: ")}
                        {formatNumber(
                          Math.floor(
                            Math.max(
                              Number(
                                metalBalance(selectedPlanet.data, speedMining)
                              ) -
                                worker * TRITIUM_TRANSPORTSHIP_PRICE,
                              0
                            )
                          )
                        )}
                      </div>
                    </span>
                  </div>
                  <div className={styles.box}>
                    {t("Transport Ship")}
                    <Slider
                      min={0}
                      max={MaxbyLevel - Number(currentShips)}
                      defaultValue={2}
                      value={worker}
                      onChange={handleSliderChange}
                      valueLabelDisplay="auto"
                      sx={{
                        color: "#5850B4",
                        "& .MuiSlider-thumb": {
                          borderRadius: "1px",
                        },
                        "& .MuiSlider-rail": {
                          borderRadius: "0px",
                        },
                      }}
                    />
                  </div>
                  <div>
                    <input
                      className={styles.input}
                      type="number"
                      value={worker}
                      onChange={(value: any) => {
                        setWorker(value)
                      }}
                    />
                  </div>
                  <br />
                  <Row>
                    <Col>
                      <div>
                        <div className={styles.specialTitle}>
                          {t("Transport Ship")}
                        </div>
                        <hr />
                        <div className={styles.lineDetails}>
                          {t("Available")} {": "}
                          <span className={styles.tag}>{currentShips}</span>
                          {"/"}
                          <span className={styles.tag}>{MaxbyLevel}</span>
                          <br />
                        </div>
                        <div className={styles.lineDetails}>
                          {t("Capacity")} {": "}
                          {Number(currentShips) *
                            TRITIUM_TRANSPORTSHIP_CAPACITY}
                          <br />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <ScifiButton
          onClick={() => {
            createTransportship(Number(planet), worker)
              .then((res) => {
                queryClient.invalidateQueries(queryKey.tritium.player.player)
                queryClient.invalidateQueries(queryKey.tritium.planet.planet)
                setWorker(0)
                setAlert(true)
              })
              .catch((error) => {
                console.error("Erro ao criar transportship:", error)
              })
          }}
          size="small"
          disabled={disableButton}
        >
          {t("Production")}
        </ScifiButton>
      </div>
      <div className={styles.simpleAlert}>
        <SimpleAlert
          open={alert}
          onClose={() => {
            setAlert(false)
          }}
        />
      </div>
    </>
  )
}

export default TransportShip
