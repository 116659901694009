import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { queryKey } from "data/query"
import { isFinish, isLocked } from "../resource/Service"
import { ScifiButton } from "components/general"
import { buyResearch } from "data/queries/research"
import styles from "./ResearchContent.module.scss"

interface Props {
  research: Research
  researcheds: Researched[]
  balance: number
}

const ResearchContent = (props: Props) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { research, researcheds, balance } = props

  const [end, setEnd] = useState(false)
  const [enoughBalance, setEnoughBalance] = useState(false)
  const [locked, setLocked] = useState(true)

  useEffect(() => {
    setEnd(isFinish(research, researcheds))
    setLocked(isLocked(research, researcheds))
    if (Number(research.cost) < balance) {
      setEnoughBalance(true)
    } else {
      setEnoughBalance(false)
    }
  }, [balance, research, researcheds])

  return (
    <div className={styles.item}>
      <div
        className={
          research.category === 1
            ? styles.contentImage1
            : research.category === 2
            ? styles.contentImage2
            : styles.contentImage3
        }
      >
        <img
          src={`/assets/scenes/sceneResearch${research.id}.jpg`}
          alt={`research${research.title}`}
          draggable={false}
        />
      </div>
      <div className={styles.title}>
        {t(`${research.title}`)} {" ["} {research.cost} {" RP]"}
      </div>
      <div className={styles.description}>{t(`${research.description}`)}</div>
      <div className={styles.subTitle}>{t("Effect")}</div>
      <div className={styles.effectDescription}>
        {t(`${research.effectDescription}`)}
      </div>
      <br />
      <div className={styles.button}>
        <ScifiButton
          id="btnResearch"
          onClick={() => {
            buyResearch(Number(research.code))
              .then(() => {
                queryClient.invalidateQueries(queryKey.tritium.planet.planets)
                queryClient.invalidateQueries(
                  queryKey.tritium.researched.details
                )
                queryClient.invalidateQueries(queryKey.tritium.player.player)
                setEnd(true)
              })
              .catch((error) => {
                console.error("An error occurred:", error)
              })
              .finally(() => {
                setEnd(true)
              })
          }}
          disabled={locked || end || !enoughBalance}
        >
          {locked && end
            ? t("Completed")
            : enoughBalance
            ? t("Research")
            : Number(research.cost) - balance + " " + t("RP left")}
        </ScifiButton>
      </div>
    </div>
  )
}

export default ResearchContent
