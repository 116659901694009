import { useTranslation } from "react-i18next"
import styles from "./Footer.module.scss"

const Footer = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.footer}>
      &copy; 2023 2024 by Terra Tritium. {t("All rights reserved")} |{" "}
      {t("Terms of Service")} |{t("Privacy")} |{" "}
      <a
        href={"https://docs.terratritium.com/version"}
        target="_blank"
        rel="noreferrer"
      >
        <b>Version 0.2.8 Beta</b>
      </a>
    </div>
  )
}

export default Footer
